import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { publicRoutes } from "pages/Routes";

import Loader from "components/Loader";
import { setUserFlow, setFamilySearchAuthCode } from "services";
import { REACT_APP_ENV_URL } from "utils/env";

const RedirectToLogin = () => {
  const { loginWithRedirect: auth0LoginWithRedirect, isAuthenticated } = useAuth0();
  const locationUse = useLocation();
  const history = useHistory();
  const locationName = locationUse.pathname;
  const publicRoute = publicRoutes.find((route) => route.path === locationName);
  const search = locationUse?.search;
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');

  useEffect(() => {
    if (isAuthenticated) return;

    if (!publicRoute) {
      setUserFlow("signIn");
      if (locationName === "/login") {
        auth0LoginWithRedirect();
      } else if (locationName === "/link-accounts") {
        auth0LoginWithRedirect({
          authorizationParams: {
            roleOptions: "hideSignUpSocial",
          },
          appState: {
            returnTo: `/accounts${search}`,
          },
        });
      }
      else if (locationName === "/signup-with-familysearch") {
        if (code) {
          setFamilySearchAuthCode(code);
          auth0LoginWithRedirect({
            authorizationParams: {
              roleOptions: "fsAccountCreation",
              screen_hint: "signup",
            },
            appState: {
              returnTo: `/import-family-search-tree?code=${code}`,
            },
          });
        }
        else {
          const url = `https://ident.familysearch.org/cis-web/oauth2/v3/authorization?response_type=code&scope=openid%20profile%20email%20qualifies_for_affiliate_account%20country&client_id=6FPD-2YHT-GBN8-1GXV-47N9-W86Y-B7MB-255H&redirect_uri=${REACT_APP_ENV_URL}/signup-with-familysearch`;
          window.open(url, "_self", `width=399,height=759`);
        }
      }
      else {
        auth0LoginWithRedirect({
          appState: {
            returnTo: `${locationName}${search}`,
          },
        });
      }
    }
  }, [auth0LoginWithRedirect, history, locationName, publicRoute, search, isAuthenticated, code]);

  return (
    <>
      <Loader />
    </>
  );
};

export default RedirectToLogin;
