/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActivationCodesRedeemActivationCodeData,
  ActivationCodesRedeemActivationCodeParams,
  AdminActivationCodesGenerateActivationCodesData,
  AdminActivationCodesGenerateActivationCodesParams,
  AdminActivationCodesReserveActivationCodesData
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class ActivationCodes extends HttpClient {
  /**
   * No description
   *
   * @tags ActivationCodes
   * @name ActivationCodesRedeemActivationCode
   * @request POST:/api/ActivationCodes/redeem-activation-code
   * @secure
   */
  activationCodesRedeemActivationCode = (
    query: ActivationCodesRedeemActivationCodeParams,
    params: RequestParams = {}
  ) =>
    this.request<ActivationCodesRedeemActivationCodeData, any>({
      path: `/api/ActivationCodes/redeem-activation-code`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesGenerateActivationCodes
   * @request POST:/api/admin/ActivationCodes/generate-activation-codes
   * @secure
   */
  adminActivationCodesGenerateActivationCodes = (
    query: AdminActivationCodesGenerateActivationCodesParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesGenerateActivationCodesData, any>({
      path: `/api/admin/ActivationCodes/generate-activation-codes`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags ActivationCodes
   * @name AdminActivationCodesReserveActivationCodes
   * @request GET:/api/admin/ActivationCodes/reserve_activation_codes/{itemCode}/{numberOfCodes}
   * @secure
   */
  adminActivationCodesReserveActivationCodes = (
    itemCode: string,
    numberOfCodes: number,
    params: RequestParams = {}
  ) =>
    this.request<AdminActivationCodesReserveActivationCodesData, any>({
      path: `/api/admin/ActivationCodes/reserve_activation_codes/${itemCode}/${numberOfCodes}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
