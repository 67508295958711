import { lazy } from "react";

export const LoggedOutHomepage = lazy(() => import("pages/LoggedOutHomepage"));
export const LoggedOutConnectionspage = lazy(() => import("pages/LoggedOutConnectionspage"));
export const LoggedOutTreespage = lazy(() => import("pages/LoggedOutTreespage"));
export const Terms = lazy(() => import("pages/Terms"));
export const Privacy = lazy(() => import("pages/Privacy"));
export const PublicRedirector = lazy(() => import("pages/PublicRedirector"));
export const ViewStoryBook = lazy(() => import("pages/Books/ViewStoriedBook"));
export const OrderStoryBook = lazy(() => import("pages/Books/ReorderStoriedbook"));
export const DPA = lazy(() => import("pages/Dpa"));
export const GroupInvitationPage = lazy(() => import("pages/NewGroup/InvitationPage"));
export const AboutGroup = lazy(() => import("pages/AboutGroup"));
export const LoggedOutStoriesPage = lazy(() => import("pages/LoggedOutStoriesPage"));
export const PlanPage = lazy(() => import("pages/SettingsPage/PlanPage"));
export const ResourcesPage = lazy(() => import("pages/Resources"));
export const LoggedOutFreeTrialBundle = lazy(() => import("pages/FreeTrials"));
export const LoggedOutFreeTrials = lazy(() => import("pages/LoggedOutFreeTrials"));
export const LoggedOutPlanSelection = lazy(() => import("pages/LoggedOutPlanSelection"));
export const AboutPage = lazy(() => import("pages/AboutPage/AboutPage"));
export const Ccpa = lazy(() => import("pages/Ccpa"));
export const StoriedBooksPage = lazy(() => import("pages/StoriedBooks"));
export const OfferPage = lazy(() => import("pages/OfferPage"));
export const MobileIframeTest = lazy(() => import("pages/mobileIfreameTest"));
export const PersonProfile = lazy(() => import("pages/PersonProfile"));
export const AnnualOnly = lazy(() => import("pages/Payment/AnnualOnly"));
export const Ultimate = lazy(() => import("pages/Payment/Ultimate"));
export const Linking = lazy(() => import("pages/Linking"));
