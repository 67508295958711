import { AUDIO, IMAGE, PDF } from "pages/Media/helpers";
import { isMediaChanges } from "./draftChangeField";

const setMviewSaveBtnFileCond = (state, MviewSaveBtn, setMviewSaveBtnFile, selectedFile) => {
  !state && !MviewSaveBtn && setMviewSaveBtnFile(selectedFile);
};

const _handleValidate = (e, step) => {
  if (step !== 2 && e.key === "Enter") {
    e.preventDefault();
  }
};

const getRecordId = (recordId, view, storyId) => {
  if (recordId) {
    return recordId;
  } else if (storyId && view?.storyExternalImages?.[0]?.storyImagePath) {
    return true;
  }
  return null;
};

const isLoadingFn = (storyId, isLoading, recordId, _isLoading, isPromptLoading) => {
  if (_isLoading) {
    return true;
  } else if (storyId || recordId || isPromptLoading) {
    return isLoading;
  }
  return false;
};

const _setMviewSaveBtn = ({
  data,
  layout,
  setLayoutIdS,
  setMviewSaveBtnFile,
  selectedFile,
  state,
  MviewSaveBtn
}) => {
  if (data) {
    setLayoutIdS(layout);
    setMviewSaveBtnFileCond(state, MviewSaveBtn, setMviewSaveBtnFile, selectedFile);
  } else {
    setMviewSaveBtnFile([{}, {}]);
    setLayoutIdS(null);
  }
};

export const QuillToolbarOptions = [
  { name: "Bold", style: "ql-bold" },
  { name: "Italic", style: "ql-italic" },
  { name: "Underline", style: "ql-underline" },
  { name: "Bulleted List", style: "ql-list", value: "bullet" },
  { name: "Numbered List", style: "ql-list", value: "ordered" }
];

export const StoryStatus = {
  PUBLISHED: "Published",
  DRAFT: "Draft"
};

export const MediaTypes = {
  IMAGE,
  AUDIO,
  PDF
};

export const isVaildDrfat = (formik, selectedFile, existImages) =>
  (formik.dirty || isMediaChanges(selectedFile, existImages)) &&
  formik.values?.status !== "Published";

const handleDraftSave = ({
  formik,
  promptId,
  viewTree = false,
  changeFields,
  terminateEvent,
  storyAssistSave
}) => {
  if (viewTree) {
    formik.setFieldValue("viewTree", viewTree);
  }
  if (changeFields) {
    formik.setFieldValue("changeFields", changeFields);
  }

  if (terminateEvent) {
    formik.setFieldValue("terminateEvent", true);
  }
  if (storyAssistSave) {
    formik.setFieldValue("storyAssistSave", true);
  }
  formik.setFieldValue("status", "Draft");
  setTimeout(() => {
    formik.handleSubmit();
  }, 500);
};

const checkWindowidthSmall = (step, windowWidth) => {
  return step > 0 && windowWidth < 548;
};
const checkWindowidthLarge = (step, windowWidth) => {
  return step > 0 && windowWidth > 547;
};
const checkWindowidthLargeStep0 = (step, windowWidth) => {
  return windowWidth > 547 || step === 0;
};

const scrollpage = () => {
  let storyDiv = document.querySelector(".create-story.story-page-wrap"),
    cord = storyDiv?.getBoundingClientRect(),
    storyWraper = document.getElementById("create-story-header");
  if (cord?.y > -31) {
    storyWraper?.classList?.remove("fixed");
    storyWraper?.classList?.add("absolute");
  } else {
    storyWraper?.classList?.remove("absolute");
    storyWraper?.classList?.add("fixed");
  }
};

const getCropSize = (selectedFile) => {
  if (selectedFile.length === 1) {
    const aspectRatio = selectedFile[0].width / selectedFile[0].height;
    if (aspectRatio > 1) {
      return { width: 720, height: 270, mobile: { width: 720, height: 270 } };
    } else {
      return { width: 180, height: 270, mobile: { width: 180, height: 270 } };
    }
  } else {
    return { width: 360, height: 270, mobile: { width: 360, height: 270 } };
  }
};
const imgRatioMap = {
  0.6666666666666666: "2/3",
  1.3333333333333333: "4/3",
  2.6666666666666665: "8/3"
};
const imgRatioReverseMap = {
  "2/3": 2 / 3,
  "4/3": 4 / 3,
  "8/3": 8 / 3
};
const imgOrientation = (selectedFile) => {
  if (selectedFile.length === 1) {
    if (selectedFile[0]?.mediaObj?.croppingLayoutRatio) {
      return selectedFile[0].mediaObj.croppingLayoutRatio === 6 / 9 ||
        imgRatioReverseMap[selectedFile[0].mediaObj.croppingLayoutRatio] === 6 / 9
        ? "vertical"
        : "horizontal";
    }
  }
  return null;
};
export {
  _handleValidate,
  _setMviewSaveBtn,
  checkWindowidthLarge,
  checkWindowidthLargeStep0,
  checkWindowidthSmall,
  getCropSize,
  getRecordId,
  handleDraftSave,
  imgOrientation,
  imgRatioMap,
  imgRatioReverseMap,
  isLoadingFn,
  scrollpage
};
