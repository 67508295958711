import { create } from "zustand";

interface PotentialNodeState {
  recentPotentialNode: any | null;
  setRecentPotentialNode: (node: any) => void;
  removeRecentPotentialNode: () => void;
}

export const usePotentialNodeStore = create<PotentialNodeState>((set) => ({
  recentPotentialNode: null,
  setRecentPotentialNode: (node) => set({ recentPotentialNode: node }),
  removeRecentPotentialNode: () => set({ recentPotentialNode: null }),
}));