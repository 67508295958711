import jwt_decode from "jwt-decode";
import { usePotentialNodeStore } from "pages/Home/Hints/potentialNodeStore";
import cookie from "react-cookies";

export function setOwner(ownerId: any) {
  localStorage.setItem("ownerId", JSON.stringify(ownerId));
  localStorage.setItem("switch_status", `${true}`);
}

export function setTreePan(value: string) {
  localStorage.setItem("treePan", value);
}

export function setFocusSiblingAdded(value: string) {
  localStorage.setItem("focusSiblingAdded", value);
}

export function getFocusSiblingAdded() {
  return JSON.parse(localStorage.getItem("focusSiblingAdded") ?? "null") ?? false;
}

export function getTreePan() {
  return JSON.parse(localStorage.getItem("treePan") ?? "null") ?? false;
}

export function setUserCheck(check: any) {
  cookie.save("checkUser", check, { path: "/" });
}

export function setRedirectToAccountLinking(check: any) {
  cookie.save("redirectToAccountLinking", check, { path: "/" });
}

export function setFileCookie(file: any) {
  localStorage.setItem("bookCoverFile", JSON.stringify(file));
}

export function getFileCookie() {
  return JSON.parse(localStorage.getItem("bookCoverFile") ?? "null") || null;
}

export const setSelectedMediaFileUrl = (file: any) => {
  localStorage.setItem("bookCoverMediaUrl", JSON.stringify(file));
};

export const getSelectedMediaFileUrl = () => {
  return JSON.parse(localStorage.getItem("bookCoverMediaUrl") ?? "null") || null;
};

export const removeSelectedMediaFileUrl = () => {
  localStorage.removeItem("bookCoverMediaUrl");
};

export function getRedirectToAccountLinking() {
  return cookie.load("redirectToAccountLinking") || null;
}

export function removeRedirectToAccountLinking() {
  cookie.remove("redirectToAccountLinking", { path: "/" });
}

export function getUserCheck() {
  return cookie.load("checkUser") || null;
}

export function removeUploadTree() {
  cookie.remove("uploadTree", { path: "/" });
}

export function setRedirectToRegistration(redirectToRegister: any) {
  cookie.save("redirectToRegister", redirectToRegister, { path: "/" });
}

export function getRedirectToRegister() {
  return cookie.load("redirectToRegister") || null;
}

export function removeRedirectToRegister() {
  cookie.remove("redirectToRegister", { path: "/" });
}

export function getRedirectToAccounts() {
  return cookie.load("redirectToAccounts") || null;
}

export function removeRedirectToAccounts() {
  cookie.remove("redirectToAccounts", { path: "/" });
}

export function setNavigateToFamily() {
  cookie.save("redirectToFamily", `${true}`, { path: "/" });
}

export function getNavigateToFamily() {
  return cookie.load("redirectToFamily") || null;
}

export function removeNavigateToFamily() {
  cookie.remove("redirectToFamily", { path: "/" });
}

export function setNewFamilySearchTreeId(newTreeId: any) {
  cookie.save("newFamilySearchTreeId", newTreeId, { path: "/" });
}

export function setFamilySearchhomePersonId(homePersonId: any) {
  cookie.save("newFamilySearchHomePersonId", homePersonId, { path: "/" });
}

export function setFamilySearchRequestId(requestId: any) {
  cookie.save("newFamilySearchRequestId", requestId, { path: "/" });
}

export function getFamilySearchRequestId() {
  return cookie.load("newFamilySearchRequestId") || null;
}

export function getFamilySearchTreeId() {
  return cookie.load("newFamilySearchTreeId") || null;
}

export function getFamilySearchHomePersonId() {
  return cookie.load("newFamilySearchHomePersonId") || null;
}

export function removeFamilySearchTreeId() {
  cookie.remove("newFamilySearchTreeId", { path: "/" });
}

export function removeFamilySearchHomePersonId() {
  cookie.remove("newFamilySearchHomePersonId", { path: "/" });
}

export function removeFamilySearchRequestId() {
  cookie.remove("newFamilySearchRequestId", { path: "/" });
}

export function removeCookies() {
  for (const k in window.localStorage) {
    if (k.includes("rfsn")) continue;
    delete window.localStorage[k];
  }
  cookie.remove("checkUser", { path: "/" });
  cookie.remove("accessToken", { path: "/" });
  cookie.remove("recent-tree", { path: "/" });
  cookie.remove("userFlow", { path: "/" });
  cookie.remove("uploadTree", { path: "/" });
  cookie.remove("checkNewSubscriber", { path: "/" });
  cookie.remove("s_sub", { path: "/" });
  cookie.remove("signUpUser", { path: "/" });
  cookie.remove("groupmember-cookie", { path: "/" });
  cookie.remove("groupUser", { path: "/" });
  cookie.remove("isGroupSignupFLow", { path: "/" });
  cookie.remove("GroupPlanFlow", { path: "/" });
  cookie.remove("GroupPlanFlowSignIn", { path: "/" });
  cookie.remove("signInUpRedirect", { path: "/" });
  cookie.remove("signUpGroupUser", { path: "/" });
  cookie.remove("rootSignup", { path: "/" });
  cookie.remove("payment", { path: "/" });
  cookie.remove("lohistoricalSearch", { path: "/" });
  cookie.remove("SearchFormTreeInfo", { path: "/" });
  cookie.remove("offerSignUp", { path: "/" });
  cookie.remove("authProvider", { path: "/" });
  cookie.remove("redirectToRegister", { path: "/" });
  cookie.remove("startTreeRequestID", { path: "/" });
  cookie.remove("redirectToAccountLinking", { path: "/" });
  cookie.remove("accountsMerged", { path: "/" });
  cookie.remove("isNodeWithHintCentered", { path: "/" });
  cookie.remove("_conv_v", { path: "/" });
  cookie.remove("isTreeTaskPanelOpen", { path: "/" });
  cookie.remove("updateSubscription", { path: "/" });
  cookie.remove("familySearchToken", { path: "/" });
  cookie.remove("familySearchAuthCode", { path: "/" });
  cookie.remove("forceFailureApis", { path: "/" });
  cookie.remove("force-vite", { path: "/" });
}

export function forceViteEnabled() {
  return cookie.load("force-vite") === "true";
}

export const setFamilySearchToken = (familySearchToken: any) => {
  cookie.save("familySearchToken", familySearchToken, { path: "/", maxAge: 7200 });
};

export function removeFamilySearchToken() {
  cookie.remove("familySearchToken", { path: "/" });
}

export const getFamilySearchToken = () => {
  return cookie.load("familySearchToken") || null;
};

export const setFamilySearchAuthCode = (familySearchAuthCode: any) => {
  cookie.save("familySearchAuthCode", familySearchAuthCode, { path: "/" });
};

export function removeFamilySearchAuthCode() {
  cookie.remove("familySearchAuthCode", { path: "/" });
}

export const getFamilySearchAuthCode = () => {
  return cookie.load("familySearchAuthCode") || null;
};

export function setForceFailureApis(apiList: any) {
  cookie.save("forceFailureApis", apiList, { path: "/" });
}

export function getForceFailureApis() {
  return cookie.load("forceFailureApis") || null;
}

export function setAccessCode(accessCode: any) {
  cookie.save("accessCode", accessCode, { path: "/", maxAge: 86400 });
}

export function getAccessCode() {
  return cookie.load("accessCode") || null;
}

export function setAccessToken(accessToken: any) {
  cookie.save("accessToken", accessToken, { path: "/", maxAge: 86400 });
}

export function setUserFlow(userFlow: any) {
  cookie.save("userFlow", userFlow, { path: "/", maxAge: 86400 });
}

export function setUploadTree(upload: any) {
  cookie.save("uploadTree", upload, { path: "/" });
}

export function setSignUpUser(upload: any) {
  cookie.save("signUpUser", upload, { path: "/" });
}

export function setSignUpGroupUser(upload: any) {
  cookie.save("signUpGroupUser", upload, { path: "/" });
}

export function setSignInUpRedirect(upload: any) {
  cookie.save("signInUpRedirect", upload, { path: "/" });
}

export function setInviteGroupsIds(ids: any) {
  cookie.save("inviteGroupsIds", ids, { path: "/" });
}

export function setAccountsMerged(accounts: any) {
  cookie.save("accountsMerged", accounts, { path: "/" });
}

export function getAccountsMerged() {
  return cookie.load("accountsMerged") || null;
}

export function getAccessToken(): string | null {
  return cookie.load("accessToken") || null;
}

export function getSignUpUser() {
  return cookie.load("signUpUser") || null;
}

export function getSignUpGroupUser() {
  return cookie.load("signUpGroupUser") || null;
}

export function getSignUpInRedirect() {
  return cookie.load("signInUpRedirect") || null;
}

export function getInviteGroupsIds() {
  return cookie.load("inviteGroupsIds") || null;
}

export function getUserFlow() {
  return cookie.load("userFlow") || null;
}

export function getShowPaymentOptions() {
  return cookie.load("show-payment-options") || null;
}

export function getOwner() {
  return JSON.parse(localStorage.getItem("ownerId") ?? "null") || null;
}

export function setCardTooltip(value: any) {
  localStorage.setItem("card-tooltip", value);
}

export function setNewTree(value: any) {
  localStorage.setItem("new-tree", value);
}

export function getNewTree() {
  return localStorage.getItem("new-tree");
}

// Recent Family Tree
export function setRecentTree(recentTree: any) {
  cookie.save("recent-tree", recentTree, { path: "/" });
}

export function getRecentTree() {
  return cookie.load("recent-tree") || null;
}

export function removeRecentTree() {
  cookie.remove("recent-tree", { path: "/" });
}

export function setRecentPotentialNode(recentNode: any) {
  usePotentialNodeStore.getState().setRecentPotentialNode(recentNode);
}

export function getRecentPotentialNode() {
  return usePotentialNodeStore.getState().recentPotentialNode;
}

export function removeRecentPotentialNode() {
  usePotentialNodeStore.getState().removeRecentPotentialNode();
}

export function removeSignUpGroupUser() {
  cookie.remove("signUpGroupUser", { path: "/" });
}

export function removeSignInUpRedirect() {
  cookie.remove("signInUpRedirect", { path: "/" });
}

export function setVerifiedCookie() {
  const d = new Date();
  d.setTime(d.getTime() + 15 * 24 * 60 * 60 * 1000);
  cookie.save("verified-cookie", `${true}`, { path: "/", expires: d });
}

export function isUserOwner(ownerId: any) {
  return ownerId === getOwner() ? true : false;
}

export function isUserOwnerOrContributor(personCreatorId: any, petContributorId: any) {
  let isOwnerBool = false;
  if (personCreatorId === getOwner()) {
    isOwnerBool = true;
  }
  if (petContributorId === getOwner()) {
    isOwnerBool = true;
  }
  return isOwnerBool;
}

type JWTPayload = {
  extension_WebsiteId: string;
  extension_PlanId: string;
  extension_StartDate: string;
  extension_EndDate: string;
};
export function decodeJWTtoken() {
  try {
    if (getAccessToken()) {
      const payload = jwt_decode<JWTPayload>(getAccessToken() ?? "");
      return payload;
    }
    return null;
  } catch {
    return null;
  }
}

export function shareStoryDataLayer(set: any) {
  if (set) {
    cookie.save("shareStoryDataLayer", `${true}`, undefined!);
  } else {
    cookie.remove("shareStoryDataLayer");
  }
}

export function loadShareStoryDataLayer() {
  return cookie.load("shareStoryDataLayer") || null;
}

export function groupStoryDataLayer(set: any) {
  if (set) {
    cookie.save("groupStoryDataLayer", `${true}`, undefined!);
  } else {
    cookie.remove("groupStoryDataLayer");
  }
}

export function loadGroupDataLayer() {
  return cookie.load("groupStoryDataLayer") || null;
}
export function paymentSuccessNewSubs(bool: any) {
  cookie.save("checkNewSubscriber", bool, {
    path: "/",
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 + 10 * 60 * 1000)
  });
}
export function SetCookieSubs(data: any) {
  cookie.save("s_sub", data, {
    path: "/",
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 + 10 * 60 * 1000)
  });
}
export function getSubscription() {
  return cookie.load("checkNewSubscriber") || null;
}
export function getSubscriptionDetails() {
  return cookie.load("s_sub") || null;
}
export function setVisitorIDAccess(data: any) {
  cookie.save("visitor_id", data, {
    path: "/",
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 10000 + 10 * 60 * 1000)
  });
}
export function getVisitorIDCookie() {
  return cookie.load("visitor_id") || null;
}

export function setGroupInfoCookie(data: any) {
  const d = new Date();
  d.setTime(d.getTime() + 15 * 24 * 60 * 60 * 1000);
  cookie.save("groupmember-cookie", JSON.stringify(data), { path: "/", expires: d });
}

export function getGroupInfoCookie() {
  return cookie.load("groupmember-cookie") || null;
}

export function getInviteDetails() {
  return cookie.load("inviteDetails") || null;
}

export function removeInviteDetails() {
  cookie.remove("inviteDetails", { path: "/" });
}

export function setUserCreated(check: any) {
  cookie.save("userCreated", check, { path: "/" });
}

export function getUserCreated() {
  return cookie.load("userCreated") || null;
}

export function removeUserCreated() {
  cookie.remove("userCreated", { path: "/" });
}

export function setGroupSignUpFlow(check: any) {
  cookie.save("isGroupSignupFLow", check, { path: "/" });
}

export function getGroupSignUpFlow() {
  return cookie.load("isGroupSignupFLow") || null;
}

export function removeGroupSignUpFlow() {
  cookie.remove("isGroupSignupFLow", { path: "/" });
}

export function setSignUpGroupPlanFlow(check: any) {
  cookie.save("GroupPlanFlow", check, { path: "/" });
}

export function getSignUpGroupPlanFlow() {
  return cookie.load("GroupPlanFlow") || null;
}

export function removeSignUpGroupPlanFlow() {
  cookie.remove("GroupPlanFlow", { path: "/" });
}

export function getSignInGroupPlanFlow() {
  return cookie.load("GroupPlanFlowSignIn") || null;
}

export function removeSignInGroupPlanFlow() {
  cookie.remove("GroupPlanFlowSignIn", { path: "/" });
}

export function setViaMarketingURL() {
  const d = new Date();
  d.setTime(d.getTime() + 15 * 24 * 60 * 60 * 1000);
  cookie.save("marketing_url", `${true}`, { path: "/", expires: d });
}

export function getViaMarketingURL() {
  return cookie.load("marketing_url") === "true" ? true : false;
}
export function removeViaMarketingURL() {
  cookie.remove("marketing_url", { path: "/" });
}

export function setPaymentCookies(check: any) {
  cookie.save("payment", check, { path: "/" });
}

export function getPaymentCookies() {
  return cookie.load("payment") || null;
}
export function removePaymentCookies() {
  cookie.remove("payment", { path: "/" });
}

export function setSearchCookies(check: any) {
  cookie.save("search", check, { path: "/" });
}
export function getSearchCookies() {
  return cookie.load("search") || null;
}
export function removeSearchCookies() {
  cookie.remove("search", { path: "/" });
}

export function setRootSignUp(rootSignup: any) {
  cookie.save("rootSignup", rootSignup, undefined!);
}

export function removeOfferSignUpUser() {
  cookie.remove("offerSignUp", { path: "/" });
}

export function getOfferSignUp() {
  return cookie.load("offerSignUp") || null;
}

export function getRootSignUp() {
  return cookie.load("rootSignup") || null;
}

export function setLoHistoricalSearchResult(root: any) {
  cookie.save("lohistoricalSearch", root, { path: "/" });
}
export function getLoHistoricalSearchResult() {
  return cookie.load("lohistoricalSearch") || null;
}
export function removeLoHistoricalSearchResult() {
  cookie.remove("lohistoricalSearch", { path: "/" });
}

export function saveStoryAssist(value: any) {
  cookie.save("storyAssist", value, { path: "/" });
}

export function getStoryAssist() {
  return cookie.load("storyAssist") ?? true;
}

// search form typehead id
export function setSearchFormTreeInfo(value: any) {
  cookie.save("SearchFormTreeInfo", JSON.stringify(value), { path: "/" });
}

export function setShareableStoryID(storyId: any) {
  sessionStorage.setItem("loggedInShareableStory", storyId);
}
export function getLoggedInViaShareableID() {
  return sessionStorage.getItem("loggedInShareableStory") || null;
}
export function removeLoggedInViaShareableID() {
  sessionStorage.removeItem("loggedInShareableStory");
}

export function setInvitationStoryID(storyId: any) {
  sessionStorage.setItem("loggedInViaStoryPreview", `${true}`);
  sessionStorage.setItem("storyPreviewId", storyId);
}

export function setcFillialId(childData: any) {
  sessionStorage.setItem("cFillialId", childData);
}

export function getcFillialId() {
  return sessionStorage.getItem("cFillialId");
}


export function removeFillialId() {
  return sessionStorage.removeItem("cFillialId");
}

export function removeInvitationStoryID() {
  sessionStorage.removeItem("storyPreviewId");
  sessionStorage.removeItem("loggedInViaStoryPreview");
}

export function setStartTreeRequestID(value: any) {
  cookie.save("startTreeRequestID", value, { path: "/" });
}

export function getStartTreeRequestID() {
  return cookie.load("startTreeRequestID") || null;
}

export function removeStartTreeRequestID() {
  cookie.remove("startTreeRequestID", { path: "/" });
}

export function addRegisterType(value: any) {
  cookie.save("regType", JSON.stringify(value), { path: "/" });
}

export function loadRegisterType() {
  return cookie.load("regType") ?? null;
}

export function removeRegisterType() {
  cookie.remove("regType", { path: "/" });
}

export function loadGroupPage() {
  return cookie.load("fromPage") ?? null;
}

export function removeGroupPage() {
  cookie.remove("fromPage", { path: "/" });
}

export function addOfferEvent(value: any) {
  cookie.save("offerType", JSON.stringify(value), { path: "/" });
}

export function loadOfferEvent() {
  return cookie.load("offerType") ?? null;
}

export function removeOfferEvent() {
  cookie.remove("offerType", { path: "/" });
}

export function loadSearchCollectionId() {
  return cookie.load("searchCollectionId") ?? null;
}
export function removeSearchCollectionId() {
  cookie.remove("searchCollectionId", { path: "/" });
}

export function setIsNodeWithHintCentered(value: any) {
  cookie.save("isNodeWithHintCentered", value, undefined!);
}

export function getIsNodeWithHintCentered() {
  return cookie.load("isNodeWithHintCentered") || false;
}

export function getConvVCookie() {
  return cookie.load("_conv_v") || null;
}

export function setIsTreeTaskPanelOpen(value: any) {
  cookie.save("isTreeTaskPanelOpen", value, undefined!);
}

export function getIsTreeTaskPanelOpen() {
  return cookie.load("isTreeTaskPanelOpen") ?? true;
}

export function setUpdateSubscription(value: any) {
  cookie.save("updateSubscription", value, { path: "/" });
}

export function getUpdateSubscription() {
  return cookie.load("updateSubscription") || null;
}

export function storyAssociationCookie(flag = "load") {
  if (flag === "load") {
    return cookie.load("storyAssociation") || null;
  } else if (flag === "add") {
    cookie.save("storyAssociation", `${true}`, { path: "/" });
  } else if (flag === "remove") {
    cookie.remove("storyAssociation", { path: "/" });
  }
}

export function removeUpdateSubscription() {
  cookie.remove("updateSubscription", { path: "/" });
}
export function getGoogleSessionId() {
  return cookie.load("_ga_FBNMMMS9F3") || null;
}

export function getLIHPTestCookie() {
  return cookie.load("LIHP_Test_Cookie") === "true" ? true : false;
}
