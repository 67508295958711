import { pathToRegexp } from "path-to-regexp";

function getNextJsRoutes(
  reactAppClientId: string,
  reactAppApi: string
): { path: string; canBeLoggedIn: boolean | (() => Promise<boolean>) }[] {
  return [
    { path: "/stories", canBeLoggedIn: false },
    // { path: "/stories/:version?/view/:idunno/:storyid", canBeLoggedIn: false },
    { path: "/storiedbooks", canBeLoggedIn: true },
    { path: "/groups", canBeLoggedIn: true },
    { path: "/family-trees", canBeLoggedIn: true },
    { path: "/people", canBeLoggedIn: true },
    { path: "/lp/newspaper", canBeLoggedIn: false },
    { path: "/newspaper-search", canBeLoggedIn: false },
    { path: "/newspaper-search/:route*", canBeLoggedIn: false },
    { path: "/plans", canBeLoggedIn: false },
    { path: "/about", canBeLoggedIn: true },
    { path: "/resources", canBeLoggedIn: true },
    { path: "/privacy", canBeLoggedIn: true },
    { path: "/terms", canBeLoggedIn: true },
    { path: "/ccpa", canBeLoggedIn: true },
    { path: "/dpa", canBeLoggedIn: true },
    { path: "/getstarted", canBeLoggedIn: true },
    { path: "/name-search", canBeLoggedIn: true },
    { path: "/person/profile/:id", canBeLoggedIn: false },
    { path: "/payment/bundle/trial", canBeLoggedIn: true },
    { path: "/payment/bundle/trial/grid", canBeLoggedIn: false },
    { path: "/payment/bundle/trial/ultimate", canBeLoggedIn: false },
    { path: "/payment/bundle/annual-only", canBeLoggedIn: false },
    { path: "/gifts/:rest+", canBeLoggedIn: true },
    { path: "/print", canBeLoggedIn: true },
    { path: "/storiedbook/re-order/:rest+", canBeLoggedIn: true },
    { path: "/p/:storyid", canBeLoggedIn: true },
    { path: "/book-preview/:storyid", canBeLoggedIn: true },
    { path: "/activation", canBeLoggedIn: true },
    { path: "/historical-record-search", canBeLoggedIn: true },
    { path: "/historical-record-search/:rest*", canBeLoggedIn: true },
    { path: "/stories-preview/:storyid", canBeLoggedIn: true },
    { path: "/activate", canBeLoggedIn: true },
    { path: "/prompts", canBeLoggedIn: true },
  ];
}

export function getCompiledNextRoutes(reactAppClientId: string, reactAppApi: string) {
  return getNextJsRoutes(reactAppClientId, reactAppApi).map((route) => ({
    path: pathToRegexp(route.path),
    canBeLoggedIn: route.canBeLoggedIn
  }));
}
