import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// Services
import {
  getAccessToken,
  getGoogleSessionId,
  setStartTreeRequestID
} from "../../services";

//Store
import { REACT_APP_API, REACT_APP_CLIENT_ID } from "utils/env";

const BASE_URL = REACT_APP_API;
const CLIENT_ID = REACT_APP_CLIENT_ID;;

const getCustomHeader = () => {
  const accessToken = getAccessToken();
  const bearer = `Bearer ${accessToken}`;
  const Authorization = ("Authorization", bearer);
  const requestId = uuidv4();
  setStartTreeRequestID(requestId);
  return {
    "wa-clientId": CLIENT_ID,
    "wa-requestId": requestId,
    "wa-sessionId": getGoogleSessionId(),
    Authorization
  };
};

export const startTreeApiRequest = (
  method,
  url,
  data,
  onUploadProgress,
  source,
  headers = false
) => {

  const sourceJSON = source
    ? {
      cancelToken: source.token
    }
    : {};

  if (headers && headers instanceof Object && !Array.isArray(headers)) {
    headers = {
      ...getCustomHeader(),
      ...headers
    };
  } else {
    headers = getCustomHeader();
  }

  const onUploadProgressFn = function (progressEvent) {
    if (onUploadProgress) onUploadProgress(progressEvent);
  };

  const isUploadProgressEnabled = process.env.NODE_ENV !== "test";

  return axios({
    method,
    url: `${BASE_URL}/api/${url}`,
    headers,
    data,
    ...sourceJSON,
    onUploadProgress: isUploadProgressEnabled && onUploadProgressFn
  });
};
